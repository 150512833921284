<template>
  <van-popup
    v-model="isshow"
    :close-on-click-overlay="false"
    overlay-class="overlay"
  >
    <div class="borders fs-md t-white">
      <div class="px-15 py-20">
        <h3 class="t-center">{{ $t("myNFTCard.sell") }}</h3>
        <div class="m-t-20">
          <p class="fs-lg bold">{{ $t("store.token") }}</p>
          <p class="t-color2 m-t-5">{{ cardDetail.tokenid }}</p>
        </div>
        <div class="m-t-20">
          <p class="fs-lg bold">{{ $t("myNFTCard.cardstar") }}</p>
          <p class="t-color2 m-t-5">
            {{ $t("level.level" + cardDetail.level) }}
          </p>
        </div>
        <div class="m-t-20">
          <div class="d-flex ai-center jc-between">
            <p class="fs-lg bold">{{ $t("myNFTCard.sellprice") }}</p>
            <p class="t-color2 fs-sm">{{ $t("myNFTCard.fee") }}: {{ fee }}%</p>
          </div>
          <van-field
            type="number"
            class="m-t-10 borders sellinput"
            v-model="salePrice"
            @input="oninput"
          >
            <template #right-icon>
              <span class="t-white bold fs-lg m-r-10">BNB</span>
            </template>
          </van-field>
        </div>
        <div class="m-t-20">
          <p class="fs-lg bold">{{ $t("myNFTCard.received") }}</p>
          <div
            class="
              m-t-10
              receive
              sellinput
              bg-color2
              d-flex
              ai-center
              jc-between
            "
          >
            <span>{{ receiveAmount }}</span>
            <span class="bold fs-lg">BNB</span>
          </div>
        </div>
        <div class="d-flex ai-center jc-center m-t-40">
          <van-button plain class="btn cancel_btn borders" @click="cancel">{{
            $t("public.cancel")
          }}</van-button>
          <van-button
            class="btn"
            @click="submit"
            :loading="loading"
            color="#074ce7"
            >{{ $t("public.ok") }}</van-button
          >
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import BigNumber from "bignumber.js";
import abi_C from "../common/abi/abi_C.json";
import { mapState } from "vuex";
export default {
  props: {
    isshow: { type: Boolean, default: false },
    fee: { type: String, default: "" },
    cardDetail: { type: Object, default: {} },
  },
  data() {
    return {
      salePrice: null,
      contract_address: "", //合约地址
      loading: false,
      decimals: null, //精度
      receiveAmount: null, //到账金额
    };
  },
  computed: {
    ...mapState({
      setting: (state) => state.setting,
      walletAddress: (state) => state.walletAddress,
    }),
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
  },
  mounted() {
    this.getSet();
  },
  methods: {
    oninput(e) {
      var i = (e * 100 * (100 * this.fee)) / 10000;
      var value = (e * 1000 - i * 1000) / 1000;
      // console.log(value);
      if (value <= 0) {
        this.receiveAmount = 0;
      } else {
        this.receiveAmount = value;
      }
    },
    //获取网站配置
    getSet() {
      let set = JSON.parse(this.setting);
      if (set) {
        set.forEach((element) => {
          if (element.id == 2) {
            //卡牌合约
            this.contract_address = element.value;
          }
        });
      }
    },
    //卖卡片 预备
    sellBegin() {
      this.loading = true;
      this.$axios
        .post(this.$api.cardsell + "/" + this.cardDetail.id, {
          price: this.salePrice,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.sellCard(res.data.orderId);
          } else {
            this.loading = false;
            this.$toast(res.msg);
          }
        });
    },
    // 卖卡片
    sellCard(orderId) {
      let myContract = new web3.eth.Contract(abi_C, this.contract_address);
      let price =
        "0x" +
        BigNumber(this.salePrice * Math.pow(10, 18))
          .toString(16)
          .replace(/\.\w+$/, "");
      console.log(price);
      myContract.methods
        .createAuction(this.cardDetail.tokenid, price)
        .send({ from: this.walletAddress }, (err, res) => {
          console.log(res);
          // 0=失败，1=成功，2=用户拒绝
          this.sellBack(res ? 1 : 2, res, res ? "OK" : err.message, orderId);
          if (err) {
            this.loading = false;
            this.$toast(err.message);
          }
        })
        .on("error", (err) => {
          // 用户拒绝
          console.log(err);
          this.loading = false;
          if (err.code == 4001) {
            this.$toast(err.message);
            this.isshow = false;
          }
        })
        .on("receipt", function (receipt) {
          // receipt example
          console.log(receipt);
        });
    },
    // 卖卡片 回调
    sellBack(status, hash, msg, orderId) {
      this.$axios
        .post(this.$api.cardsellback + "/" + orderId, {
          status: status,
          txhash: hash,
          msg: msg,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            setTimeout(() => {
              this.$toast(this.$t("public.suc"));
              this.$emit("getCardDetail");
            }, 4000);
          } else {
            this.loading = false;
            this.$toast(res.msg);
          }
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      if (this.salePrice <= 0) {
        this.$toast(this.$t("myNFTCard.enterprice"));
      } else {
        // 未质押 点击去出售
        this.sellBegin();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.borders {
  width: 320px;
  // height: 345px;
  h3 {
    font-size: 18px;
  }
  .btn {
    width: 80px;
    height: 30px;
    border-radius: 10px;
    font-size: 12px;
  }
  .cancel_btn {
    padding: 0;
    margin-right: 13px;
    border: 1px solid transparent;
    color: #074ce7;
  }
  .sellinput {
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 10px;
    padding: 0;
  }
  .receive {
    padding: 0 10px;
  }
}
</style>