<template>
  <div class="containers">
    <navbar></navbar>
    <div class="contents px-40 py-15">
      <div class="bg-color6 main d-flex flex-column ai-center jc-center">
        <div class="logoimg m-b-25 m-t-20">
          <van-image
            width="153px"
            height="210px"
            v-if="cardDetail.level"
            :src="require('@/assets/gif/border' + cardDetail.level + '.png')"
          />
          <van-image
            width="153px"
            height="210px"
            class="img"
            v-if="cardDetail.store"
            :src="cardDetail.store.gif_remote_url"
          />
          <!-- 0=默认未质押，1=质押中，2=已质押 -->
          <van-image
            width="50px"
            height="46px"
            class="icon"
            v-if="islogoStatus == 1"
            :src="require('@/assets/staking.png')"
          />
          <!-- 0=默认，1挂卖中 -->
          <van-image
            class="icon"
            width="50px"
            height="46px"
            v-if="islogoStatus == 2"
            :src="require('@/assets/selling.png')"
          />
          <!-- 确认中状态 -->
          <van-image
            class="icon"
            width="50px"
            height="46px"
            v-if="islogoStatus == 3"
            :src="require('@/assets/confirm_icon.png')"
          />
        </div>
        <div class="d-flex ai-center jc-between m-b-30 w-100">
          <p class="t-color10">{{ $t("myNFTCard.cardname") }}</p>
          <p class="bold" v-if="cardDetail.store">
            {{
              lang == "zh" ? cardDetail.store.name : cardDetail.store.en_name
            }}
          </p>
        </div>
        <div class="d-flex ai-center jc-between m-b-30 w-100">
          <p class="t-color10">{{ $t("myNFTCard.cardstar") }}</p>
          <p class="bold">{{ $t("level.level" + cardDetail.level) }}</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-30 w-100">
          <p class="t-color10">Token</p>
          <p class="bold">{{ cardDetail.tokenid }}</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-30 w-100">
          <p class="t-color10">{{ $t("myNFTCard.output") }}</p>
          <div class="bold d-flex ai-center">
            <van-skeleton :row="1" row-width="30px" :loading="isOutput">
              <span class="fs-xxxl t-color5">{{ devonianNum | cutZero }}</span>
            </van-skeleton>
            <span class="m-l-5">ACDC/{{ $t("myNFTCard.day") }}</span>
          </div>
        </div>
        <div
          class="d-flex ai-center jc-between m-b-30 w-100"
          v-if="islogoStatus == 2"
        >
          <p class="t-color10">{{ $t("myNFTCard.salePrice") }}</p>
          <p class="bold" v-if="cardDetail.order">
            <span class="fs-xxxl t-color5">{{
              cardDetail.order.price | cutZero
            }}</span>
            BNB
          </p>
        </div>
        <div class="d-flex ai-center jc-between m-b-30 w-100">
          <p class="t-color10">{{ $t("myNFTCard.state") }}</p>
          <!-- {{$t('myNFTCard.idle')}}  {{$t('myNFTCard.selling')}} {{ $t("myNFTCard.stakings") }} -->
          <p class="bold">{{ statusText }}</p>
        </div>
        <p class="fs-sm m-t-20" v-if="islogoStatus == 1">
          {{ $t("myNFTCard.fee") }}: {{ fee }}ACDC
        </p>
        <div v-if="isConfirm" class="w-100">
          <!-- 出售 取消出售 -->
          <van-button
            color="#FF7E00"
            class="bold btn"
            v-if="status == 1"
            @click="operation"
            >{{ $t("myNFTCard.cancelsell") }}</van-button
          >
          <!-- 赎回 -->
          <van-button
            color="#FF7E00"
            class="bold btn"
            v-else-if="status == 2"
            @click="operation"
            >{{ $t("myNFTCard.release") }}</van-button
          >
          <!-- 闲置 -->
          <div class="d-flex ai-center jc-center w-100" v-else>
            <!--  质押按钮 -->
            <!-- pledge_id  质押的卡牌ID 没有默认为0 -->
            <van-button
              color="#074CE7"
              class="bold btn btn2"
              @click="operation"
              :loading="isStakeLoading"
              v-if="Number(pledge_id) == 0"
              >{{ $t("myNFTCard.stake") }}</van-button
            >
            <!--  出售按钮 -->
            <van-button
              color="#074CE7"
              class="bold btn btn2"
              @click="isSell = !isSell"
              >{{ $t("myNFTCard.sale") }}</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <Dialog
      :isshow="isShow"
      :content="content"
      @cancel="onCancel"
      @submit="onSubmit"
    ></Dialog>
    <Sell
      :isshow="isSell"
      :cardDetail="cardDetail"
      :fee="fee"
      @cancel="oncancel"
      @getCardDetail="ongetCardDetail"
    ></Sell>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import Dialog from "@/components/common/dialog.vue";
import Sell from "@/components/sellCard.vue";
import abi_C from "../common/abi/abi_C.json";
import { mapState } from "vuex";
export default {
  components: { Dialog, Sell },
  data() {
    return {
      isShow: false,
      isSell: false,
      content: "",
      status: null, //状态
      statusText: null, //状态文字
      salePrice: null,
      devonianNum: null, //挖矿收益
      cardDetail: {},
      contract_address: "", //卡牌质押合约
      txHash: "", //交易哈希
      fee: null,
      islogoStatus: null,
      isStakeLoading: false,
      iseleaseLoading: false,
      isConfirm: true,
      isOutput: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      cardEarn: (state) => state.cardEarn,
      setting: (state) => state.setting,
      walletAddress: (state) => state.walletAddress,
    }),
    pledge_id() {
      return this.$route.query.pledge_id;
    },
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.$store.dispatch("getCardEarn"); //卡牌收益
  },
  mounted() {
    // 质押规则：
    // 当用户有卡牌在质押中，质押确认中，赎回确认中时，其他卡牌均没有质押按钮
    this.getCardDetail();
    this.getSet();
    this.getEarnCard();
    this.myContract = new web3.eth.Contract(abi_C, this.contract_address);
  },
  methods: {
    operation() {
      if (this.status == 1) {
        //出售中 取消出售
        this.content =
          this.$t("tip.tip7") +
          " " +
          this.cardDetail.tokenid +
          this.$t("tip.tip8") +
          " ?";
      } else if (this.status == 2) {
        //已质押 质押中 赎回
        this.content =
          this.$t("tip.tip10") +
          " " +
          this.cardDetail.tokenid +
          this.$t("tip.tip8") +
          " ?";
      } else {
        //闲置中 质押  出售
        this.content =
          this.$t("tip.tip9") +
          " " +
          this.cardDetail.tokenid +
          this.$t("tip.tip8") +
          " ?";
      }
      this.isShow = !this.isShow;
    },
    //卡牌详情
    getCardDetail() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .get(this.$api.carddetail + "/" + Number(this.$route.query.id), {})
        .then((res) => {
          this.$toast.clear();
          this.cardDetail = res.data;
          // res.data.pledge_status = 0;
          // res.data.sale_status = 1;
          // res.data.order = {status: 0};
          // res.data.chain_status = 1
          if (res.data.pledge_status == 2) {
            //已质押  可以赎回
            this.islogoStatus = 1;
            this.status = 2;
            this.statusText = this.$t("myNFTCard.stakings");
          } else if (res.data.sale_status == 1) {
            //出售中
            this.islogoStatus = 2;
            this.status = 1;
            this.statusText = this.$t("myNFTCard.selling");
          } else if (
            (res.data.order && res.data.order.status == 0) ||
            res.data.chain_status != 2 ||
            res.data.pledge_status == 1
          ) {
            //确认中（包括质押确认中，取消质押确认中(赎回确认中)，出售确认中，取消出售确认中）
            this.islogoStatus = 3;
            this.isConfirm = false;
            this.statusText = this.$t("myNFTCard.confiring");
          } else {
            // 未质押  默认闲置中
            this.status = 3;
            this.statusText = this.$t("myNFTCard.notstaking");
          }
          setTimeout(() => {
            this.getEarnCard();
          }, 200);
        });
    },
    //卡牌收益
    getEarnCard() {
      this.isOutput = true;
      let list = JSON.parse(this.cardEarn);
      list.forEach((element) => {
        if (element.level == this.cardDetail.level) {
          this.devonianNum = element.dig_reward_daily;
          this.isOutput = false;
        }
      });
    },
    //获取网站配置
    getSet() {
      let set = JSON.parse(this.setting);
      if (set) {
        set.forEach((element) => {
          if (element.id == 2) {
            //卡牌合约
            this.contract_address = element.value;
          } else if (element.id == 12) {
            //手续费
            this.fee = element.value;
          }
        });
      }
    },
    //质押卡片 预备
    pledgeBegin() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .post(this.$api.pledge, {
          tokenid: this.cardDetail.tokenid,
        })
        .then((res) => {
          if (res.code == 200) {
            this.pledgeCard(res.data.pledge_id);
          } else {
            this.$toast(res.msg);
          }
        });
    },
    // 质押卡片
    pledgeCard(pledge_id) {
      this.myContract.methods
        .pledge(this.cardDetail.tokenid)
        .send({ from: this.walletAddress }, (err, res) => {
          if (res) {
            this.pledgeBack(res, pledge_id);
          }
          if (err) {
            this.$toast(err.message);
          }
        })
        .on("error", (err) => {
          // 用户拒绝
          console.log(err);
          if (err.code == 4001) {
            this.$toast(err.message);
            this.isShow = false;
          }
        })
        .on("receipt", function (receipt) {
          // receipt example
          // console.log(receipt);
        });
    },
    // 质押卡片 回调
    pledgeBack(hash, pledgeid) {
      this.$axios
        .post(this.$api.pledgeCallBack, {
          txhash: hash,
          pledge_id: pledgeid,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isShow = false;
            this.getCardDetail();
            // this.$toast(this.$t("public.suc"));
          } else {
            this.$toast(res.msg);
          }
        });
    },
    //取消卖卡片 预备
    cancelBegin() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .post(this.$api.cardcancel + "/" + this.cardDetail.order.id, {})
        .then((res) => {
          if (res.code == 200) {
            this.cancelCard(this.cardDetail.order.id);
          } else {
            this.isShow = false;
            this.$toast(res.msg);
          }
        });
    },
    //取消卖卡片
    cancelCard(orderId) {
      this.myContract.methods
        .cancelAuction(this.cardDetail.tokenid)
        .send({ from: this.walletAddress }, (err, res) => {
          // 0=失败，1=成功，2=用户拒绝
          this.cancelBack(res ? 1 : 2, res, res ? "OK" : err.message, orderId);
          if (err) {
            this.$toast(err.message);
          }
        })
        .on("error", (err) => {
          // 用户拒绝
          console.log(err);
          if (err.code == 4001) {
            this.$toast(err.message);
            this.isShow = false;
          }
        })
        .on("receipt", function (receipt) {
          // receipt example
          // console.log(receipt);
        });
    },
    // 取消卖卡片 回调
    cancelBack(status, hash, msg, orderId) {
      this.$axios
        .post(this.$api.cardcancelback + "/" + orderId, {
          status: status,
          txhash: hash,
          msg: msg,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isShow = false;
            this.getCardDetail();
            // this.$toast(this.$t("public.suc"));
          } else {
            this.$toast(res.msg);
          }
        });
    },
    //出售成功回调
    ongetCardDetail() {
      this.isSell = false;
      this.getCardDetail();
    },
    // 赎回
    redeem() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .post(this.$api.cardredeem, {
          tokenid: this.cardDetail.tokenid,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isShow = false;
            this.getCardDetail();
            this.$toast(this.$t("public.suc"));
          } else {
            this.isShow = false;
            this.$toast(res.msg);
          }
        });
    },
    oncancel() {
      this.isSell = false;
    },
    onCancel() {
      this.isShow = false;
    },
    onSell() {
      this.isSell = !this.isSell;
    },
    onSubmit() {
      if (this.status == 3) {
        //未质押  点击去质押
        this.pledgeBegin();
      } else if (this.status == 2) {
        //已质押  赎回
        this.redeem();
      } else if (this.status == 1) {
        //售卖中  取消售卖
        this.cancelBegin();
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
/deep/ .van-skeleton {
  padding: 0 5px;
}
.main {
  border-radius: 10px;
  padding: 18px 16px;
  .logoimg {
    position: relative;
    .icon {
      position: absolute;
      top: 0;
      left: 0;
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.btn {
  width: 100%;
  border-radius: 10px;
  height: 35px;
  margin-top: 15px;
  font-size: 12px;
}
.btn2 {
  width: 120px;
  &:nth-child(1) {
    margin-right: 20px;
  }
}
</style>