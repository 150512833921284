<template>
  <van-popup
    v-model="isshow"
    :close-on-click-overlay="false"
    overlay-class="overlay"
  >
    <div class="borders d-flex flex-column ai-center jc-around t-white fs-md">
      <h3>{{ $t("tip.tip") }}</h3>
      <p class="t-center lh-3">{{ content }}</p>
      <div class="d-flex ai-center">
        <van-button plain class="btn cancel_btn borders" @click="cancel">{{
          $t("public.cancel")
        }}</van-button>
        <van-button class="btn" @click="submit" color="#074ce7">{{
          $t("public.ok")
        }}</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    isshow: { type: Boolean, default: false },
    content: { type: String, default: "" },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.borders {
  width: 300px;
  height: 160px;
  background-image: linear-gradient(#152031, #152031),
    linear-gradient(267deg, #391aeb, #15a3ee);
  h3 {
    font-size: 18px;
  }
  .btn {
    width: 80px;
    height: 30px;
    border-radius: 10px;
    font-size: 12px;
  }
  .cancel_btn {
    padding: 0;
    margin-right: 13px;
    border: 1px solid transparent;
    color: #074ce7;
  }
}
</style>